<template>
  <v-card class="mb-5">
    <v-card-title>
      <v-icon>mdi-checkbox-marked-outline</v-icon>
      8. Структура заболеваемости по впервые выявленным хроническим соматическим заболеваниям
    </v-card-title>

    <v-card-text>
      <report-filter v-model="filter" @input="load" />
    </v-card-text>

    <v-data-table
      hide-default-footer
      :disable-pagination="true"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
    />

    <div id="columnseries-8" />
  </v-card>
</template>

<script>
import throttle from "lodash/throttle";
import ReportFilter from "@/views/components/ReportFilter";
import { QueryHelper } from "@/services/query-helper";
import ColumnSeries from "@/services/charts/ColumnSeries";

export default {
  name: "Report8",
  components: { ReportFilter },

  data() {
    return {
      loading: false,
      options: {},
      search: "",
      items: [],
      filter: {
        date: [
          this.$date(new Date())
            .startOf("month")
            .format("YYYY-MM-DD"),
          this.$date(new Date()).format("YYYY-MM-DD"),
        ],
      },
      chart: null,
      headers: [
        {
          text: "Класс заболевания по МКБ",
          value: "icd10_name",
        },
        {
          text: "Количество работников всего",
          value: "employees_count",
          align: "center",
        },
        {
          text: "В том числе женщин",
          value: "women_count",
          align: "center",
        },
        {
          text: "В том числе мужчин",
          value: "men_count",
          align: "center",
        },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },

  async mounted() {
    this.chart = new ColumnSeries("columnseries-8", [], ["women_count", "men_count"], "icd10_name");
    this.chart.start();
  },

  destroyed() {
    this.chart.dispose();
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        const params = {
          search: this.search,
          ...QueryHelper.tableSorting(this.options),
          ...QueryHelper.filter(this.filter),
        };

        const response = await this.$http.get("v1/reports/leader/report8", { params }).then(r => r.data);

        if (response.success) {
          this.items = response.data;

          this.chart.setData(this.items);
          // this.chart.responsive();
        }
      } catch (e) {
        // TODO: Обработать ошибку
        console.error(e);
      }

      this.loading = false;
    },

    apiSearch: throttle(async function apiSearchHandler() {
      await this.load();
    }, 1000),
  },
};
</script>

<style scoped></style>
