<template>
  <v-container fluid>
    <report1 />
    <report2 />
    <report3 />
    <report4 />
    <report5 />
    <report6 />
    <report7 />
    <report8 />
    <report9 />
    <report10 />
    <report11 />
    <report12 />
    <report13 />
    <report14 />
    <report15 />
  </v-container>
</template>

<script>
import Report1 from "@/views/pages/dashboard/components/Report1.vue";
import Report2 from "@/views/pages/dashboard/components/Report2.vue";
import Report3 from "@/views/pages/dashboard/components/Report3.vue";
import Report4 from "@/views/pages/dashboard/components/Report4.vue";
import Report5 from "@/views/pages/dashboard/components/Report5.vue";
import Report6 from "@/views/pages/dashboard/components/Report6.vue";
import Report7 from "@/views/pages/dashboard/components/Report7.vue";
import Report8 from "@/views/pages/dashboard/components/Report8.vue";
import Report9 from "@/views/pages/dashboard/components/Report9.vue";
import Report10 from "@/views/pages/dashboard/components/Report10.vue";
import Report11 from "@/views/pages/dashboard/components/Report11.vue";
import Report12 from "@/views/pages/dashboard/components/Report12.vue";
import Report13 from "@/views/pages/dashboard/components/Report13.vue";
import Report14 from "@/views/pages/dashboard/components/Report14.vue";
import Report15 from "@/views/pages/dashboard/components/Report15.vue";

export default {
  name: "Dashboard",

  components: {
    Report1,
    Report2,
    Report3,
    Report4,
    Report5,
    Report6,
    Report7,
    Report8,
    Report9,
    Report10,
    Report11,
    Report12,
    Report13,
    Report14,
    Report15,
  },

  created() {
    this.$store.dispatch("reportFilter/load");
  },
};
</script>

<style scoped></style>
