<template>
  <v-menu v-model="dateRangeMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        label="Укажите дату"
        prepend-icon="event"
        readonly
        v-bind="attrs"
        :dark="false"
        :dense="dense"
        :hide-details="hideDetails"
        :class="inputClass"
        :value="rangeDates"
        v-on="on"
      />
    </template>
    <v-date-picker v-model="date" no-title scrollable range>
      <v-spacer />
      <v-btn text color="primary" @click="dateRangeMenu = false">
        Отменить
      </v-btn>
      <v-btn text color="primary" @click="clearDateFilter">
        Очистить
      </v-btn>
      <v-btn text color="primary" @click="updateDateFilter">
        Применить
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DateRangePicker",

  props: {
    inputClass: {
      type: String,
      default: "",
    },
    dense: { type: Boolean },
    hideDetails: { type: Boolean },
    value: {
      type: [String, Array],
      default: null,
    },
  },

  data: () => ({
    dateRangeMenu: false,
    date: [],
  }),

  computed: {
    rangeDates() {
      if (Array.isArray(this.value)) {
        return this.value.join(" - ");
      }

      return this.value;
    },
  },

  mounted() {
    this.date = this.value;
  },

  methods: {
    clearDateFilter() {
      this.dateRangeMenu = false;
      this.$emit("input", []);
    },

    updateDateFilter() {
      this.dateRangeMenu = false;
      this.$emit("input", [...this.date]);
    },
  },
};
</script>

<style scoped></style>
