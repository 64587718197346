<template>
  <v-card class="mb-5">
    <v-card-title>
      !10. Показатели временной нетрудоспособности
    </v-card-title>
    <v-card-text>
      <report-filter v-model="filter" @input="load" />
    </v-card-text>

    <v-data-table
      hide-default-footer
      :disable-pagination="true"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
    />
  </v-card>
</template>

<script>
import throttle from "lodash/throttle";
import ReportFilter from "@/views/components/ReportFilter.vue";
import { QueryHelper } from "@/services/query-helper";
import { reportData10 } from "./report-data";

export default {
  name: "Report10",
  components: {
    ReportFilter,
  },

  data() {
    return {
      loading: false,
      options: {},
      search: "",
      items: [],
      filter: {
        date: [
          this.$date(new Date())
            .startOf("month")
            .format("YYYY-MM-DD"),
          this.$date(new Date()).format("YYYY-MM-DD"),
        ],
      },
      chart: null,
      headers: [
        {
          text: "Диагноз по МКБ",
          value: "icd10",
          align: "center",
        },
        {
          text: "Количество человек",
          value: "count",
          align: "center",
        },
        {
          text: "Число случаев ВН",
          value: "sick_leave",
          align: "center",
        },
        {
          text: "Среднее число  дней ВН 1 случая  с данным диагнозом",
          value: "avg",
          align: "center",
        },
        {
          text: "Общее число дней  ВН по заболеванию",
          value: "sum",
          align: "center",
        },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },

  // async mounted() {
  // },

  destroyed() {},

  methods: {
    async load() {
      this.loading = true;

      try {
        this.items = reportData10;
        const params = {
          search: this.search,
          ...QueryHelper.tableSorting(this.options),
          ...QueryHelper.filter(this.filter),
        };

        const response = await this.$http.get("v1/reports/leader/report10", { params }).then(r => r.data);

        if (response.success) {
          this.items = response.data;
        }
      } catch (e) {
        // TODO: Обработать ошибку
        console.error(e);
      }

      this.loading = false;
    },

    apiSearch: throttle(async function apiSearchHandler() {
      await this.load();
    }, 1000),
  },
};
</script>

<style scoped></style>
