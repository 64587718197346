import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import toNumber from "lodash/toNumber";

export default class Pie {
  constructor(id, data, value, category, legendId) {
    this.id = id;
    this.data = data;
    this.value = value;
    this.category = category;
    this.legendId = legendId;
  }

  start() {
    // Create chart instance
    this.chart = am4core.create(this.id, am4charts.PieChart3D);

    // Let's cut a hole in our Pie chart the size of 40% the radius
    this.chart.innerRadius = am4core.percent(40);
    // Add and configure Series
    const pieSeries = this.chart.series.push(new am4charts.PieSeries3D());
    pieSeries.dataFields.value = this.value;
    pieSeries.dataFields.category = this.category;
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // Disabling labels and ticks on inner circle
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    // Disable sliding out of slices
    pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    pieSeries.slices.template.states.getKey("hover").properties.scale = 1.1;
  }

  legend() {
    // Add a legend
    this.chart.legend = new am4charts.Legend();
    this.pieSeries.labels.template.disabled = true;
  }

  setData(data) {
    const newData = data.map(item => {
      const obj = {};
      const { category } = this;

      Object.defineProperty(obj, category, {
        value: item[category],
      });

      Object.defineProperty(obj, this.value, {
        value: toNumber(item[this.value]) ? item[this.value] : 0,
      });

      return obj;
    });

    this.chart.data = newData;
  }

  setTitle(val) {
    const title = this.chart.titles.create();
    title.text = val;
    title.fontSize = 25;
    title.marginBottom = 30;
  }
}
