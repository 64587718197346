import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import toNumber from "lodash/toNumber";

export default class ColumnSeries {
  constructor(id, data, valueArray, category, maxWidth) {
    this.id = id;
    this.data = data;
    this.valueArray = valueArray;
    this.category = category;
    this.maxWidth = maxWidth;
    this.chart = am4core.create(this.id, am4charts.XYChart);
  }

  start() {
    // Create axes
    const categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = this.category;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    this.valueArray.forEach(item => {
      this.chart.yAxes.push(new am4charts.ValueAxis());

      const series2 = this.chart.series.push(new am4charts.ColumnSeries3D());
      series2.dataFields.valueY = item;

      series2.dataFields.categoryX = this.category;
      series2.columns.template.tooltipText = `${item}: [bold]{valueY}[/]`;
      series2.stacked = true;
    });

    if (this.maxWidth) {
      const label = categoryAxis.renderer.labels.template;
      label.wrap = true;
      label.maxWidth = this.maxWidth;
    }

    this.categoryAxis = categoryAxis;
  }

  dispose() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  setCountItemsView(val) {
    this.addXScrollBar();
    this.chart.scrollbarX.start = 0;
    this.chart.scrollbarX.end = val / this.data.length;
  }

  setData(data) {
    const newData = [];
    data.forEach(item => {
      const obj = {};
      const { category } = this;
      Object.defineProperty(obj, category, {
        value: item[category],
      });
      this.valueArray.forEach(n => {
        Object.defineProperty(obj, n, {
          value: toNumber(item[n]) ? toNumber(item[n]) : 0,
        });
      });
      newData.push(obj);
    });
    this.data = newData;
    this.chart.data = newData;

    this.chart.invalidateData();
  }

  setTitle(val) {
    const title = this.chart.titles.create();

    title.text = val;
    title.fontSize = 25;
    title.marginBottom = 30;
  }

  responsive() {
    const { chart } = this;
    chart.responsive.useDefault = false;
    chart.responsive.enabled = true;

    const { addXScrollBar } = this;

    const { data } = this;
    const { categoryAxis } = this;

    chart.responsive.rules.push({
      relevant: am4core.ResponsiveBreakpoints.widthS,
      state(target) {
        if (target instanceof am4charts.AxisRendererX) {
          addXScrollBar();

          chart.scrollbarX.start = 0;
          chart.scrollbarX.end = 1.5 / data.length;

          const label = categoryAxis.renderer.labels.template;
          label.wrap = true;

          label.truncate = true;
        }
        return null;
      },
    });
  }

  addXScrollBar() {
    const { chart } = this;
    // I use the scrollbarX to create a horizontal scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    // // here I set the scroolbar bottom the chart
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    // // here I chose not to show the startGrip (or button that expand the series from chart)
    chart.scrollbarX.startGrip.hide();
    chart.scrollbarX.endGrip.hide();

    // // here I chose not to show the zoomOutButton  that appear above from chart
    chart.zoomOutButton = new am4core.ZoomOutButton();
    chart.zoomOutButton.hide();
  }
}
