<template>
  <v-card class="mb-5">
    <v-card-title>
      <v-icon>mdi-checkbox-marked-outline</v-icon>
      1. Ежедневные сведения о допуске к работе по результатам предсменных/послесменных, предрейсовых/послерейсовых медицинских
      осмотров
    </v-card-title>

    <v-card-text>
      <report-filter v-model="filter" @input="load" />
    </v-card-text>

    <v-data-table
      hide-default-footer
      :disable-pagination="true"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
    />
    <h2 class="text-center chart-title">
      Число недопущенных по результатам предворительного медицинского осмотра с 1 по 07.02.2020
    </h2>
    <div id="line" class="chart-pie" />
    <h2 class="text-center chart-title">
      Причины недопуска с 1 по 07.02.2020
    </h2>
    <div id="pie" class="chart-pie" />
    <h2 class="text-center chart-title">
      Причины недопуска с 1 по 07.02.2020
    </h2>
    <div id="pie-sec" class="chart-pie" />
  </v-card>
</template>

<script>
import throttle from "lodash/throttle";
import ReportFilter from "@/views/components/ReportFilter.vue";
import { QueryHelper } from "@/services/query-helper";

import Pie from "@/services/charts/Pie";
import PieSeries3D from "@/services/charts/PieSeries3D";
import LineSeries from "@/services/charts/LineSeries";
import { reportDataLine } from "./report-data";

export default {
  name: "Report",
  components: {
    ReportFilter,
  },

  data() {
    return {
      loading: false,
      options: {},
      search: "",
      items: [],
      filter: {
        date: [
          this.$date(new Date())
            .startOf("month")
            .format("YYYY-MM-DD"),
          this.$date(new Date()).format("YYYY-MM-DD"),
        ],
      },
      pieChart: null,
      pieChart3D: null,
      lineChart: null,
      headers: [
        {
          text: "Наименование показателя",
          value: "name",
        },
        {
          text: "Количество человек всего",
          value: "employee_count",
          align: "center",
        },
        {
          text: "Не допущено",
          value: "employee_not_allowed_count",
          align: "center",
        },
        {
          text: "Направлено в стороннюю МО",
          value: "directed_to_other_mo",
          align: "center",
        },
        {
          text: "Доля (% от общего количества чел)",
          value: "percent_employee_count",
          align: "center",
        },
      ],
    };
  },

  computed: {
    lineData() {
      return reportDataLine;
    },
  },

  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },

  async mounted() {
    // Pie Chart
    this.pieChart = new Pie("pie", [], "employee_not_allowed_count", "name");
    this.pieChart.start();
    this.pieChart.legend();
    // Line Chart
    this.lineChart = new LineSeries("line", [], "count", "date");
    this.lineChart.start();
    // Pie3D Chart
    this.pieChart3D = new PieSeries3D("pie-sec", [], "employee_not_allowed_count", "name");
    this.pieChart3D.start();
    // this.pieChart3D.legend();
  },

  destroyed() {
    this.pieChart.dispose();
    this.lineChart.dispose();
    this.pieChart3D.dispose();
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        const params = {
          search: this.search,
          ...QueryHelper.tableSorting(this.options),
          ...QueryHelper.filter(this.filter),
        };

        const response = await this.$http.get("v1/reports/leader/report1", { params }).then(r => r.data);

        if (response.success) {
          this.items = response.data;
          this.pieChart.setData(this.items);
          this.pieChart3D.setData(this.items);
          this.lineChart.setData(this.lineData);
        }
      } catch (e) {
        // TODO: Обработать ошибку
        console.error(e);
      }

      this.loading = false;
    },

    apiSearch: throttle(async function apiSearchHandler() {
      await this.load();
    }, 1000),
  },
};
</script>

<style scoped></style>
