<template>
  <v-card class="mb-5">
    <v-card-title>
      !7. Характеристика заболеваемости и распределение по группам здоровья
    </v-card-title>
    <v-card-text>TODO</v-card-text>

    <!--
        <v-card-text>
          <report-filter
            v-model="filter"
            @input="load"
          />
        </v-card-text>
    -->
    <!-- <table>
      <thead>
        <tr>
          <td>Группа лиц/ класс заболеваний по МКБ</td>
        </tr>
        <tr>
        <tr>
          <td>Структура заболеваемости по классам МКБ</td>
        </tr>
        <tr>
          <td>Количество, чел</td>
          <td>Доля, %  </td>
        </tr>
        </tr>
      </thead>
      <tbody />
    </table> -->
    <!--
        <div class="table-custom">
          <div class="table-row table-row-border">
            <div class="table-col  table-col--padding table-col--padding-no-border table-col-first">
              Группа лиц/ класс заболеваний по МКБ
            </div>
            <div class="table-col">
              <div class="table-row">
                <div class="table-col table-col--padding table-col table-col--padding-no-border">
                  Структура заболеваемости по классам МКБ
                </div>
              </div>
              <div class="table-row table-row--spacebetween">
                <div class="table-col table-col--padding table-col--padding-no-border">
                  Количество, чел
                </div>
                <div class="table-col table-col--padding table-col--padding-no-border">
                  Доля, %
                </div>
              </div>
            </div>
            <div class="table-col">
              <div class="table-row">
                <div class="table-col table-col--padding table-col--padding-no-border">
                  Диагноз по МКБ/фактор риска
                </div>
              </div>
            </div>
            <div class="table-col">
              <div class="table-row">
                <div class="table-col table-col--padding table-col--padding-no-border">
                  Группа лиц/ класс заболеваний по МКБ
                </div>
              </div>
            </div>
            <div class="table-col">
              <div class="table-row">
                <div class="table-col table-col--padding table-col table-col--padding-no-border">
                  Структура заболеваемости по диагнозам /факторам риска по МКБ
                </div>
              </div>
              <div class="table-row table-row--spacebetween">
                <div class="table-col table-col--padding table-col--padding-no-border">
                  Количество, чел
                </div>
                <div class="table-col table-col--padding table-col--padding-no-border">
                  Доля, %
                </div>
              </div>
            </div>
            <div class="table-col">
              <div class="table-row">
                <div class="table-col table-col--padding table-col table-col--padding-no-border">
                  Группа здоровья в соответствии с выявленной патологией
                </div>
              </div>
              <div class="table-row table-row--spacebetween">
                <div class="table-col table-col--padding table-col--padding-no-border">
                  Группа здоровья
                </div>
                <div class="table-col table-col--padding table-col--padding-no-border">
                  Доля, %
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="(item, index) of items"
            :key="index"
            class="table-row table-row-border"
          >
            <div
              class="table-col table-col--padding table-col--padding-no-border table-col-first"
              v-html="item.name"
            />
            <div class="table-col">
              <div class="table-row table-row--spacebetween">
                <div
                  class="table-col table-col--padding table-col--padding-no-border"
                  v-html="item.date_1"
                />
                <div
                  class="table-col table-col--padding table-col--padding-no-border"
                  v-html="item.date_2"
                />
              </div>
            </div>
            <div class="table-col">
              <div class="table-row">
                <div
                  class="table-col table-col--padding table-col--padding-no-border"
                  v-html="item.date_3"
                />
              </div>
            </div>
            <div class="table-col">
              <div class="table-row table-row--spacebetween">
                <div
                  class="table-col table-col--padding table-col--padding-no-border"
                  v-html="item.date_4"
                />
                <div
                  class="table-col table-col--padding table-col--padding-no-border"
                  v-html="item.date_5"
                />
              </div>
            </div>
            <div class="table-col">
              <div class="table-row table-row--spacebetween">
                <div
                  class="table-col table-col--padding table-col--padding-no-border"
                  v-html="item.date_6"
                />
                <div
                  class="table-col table-col--padding table-col--padding-no-border"
                  v-html="item.date_7"
                />
              </div>
            </div>

            <div class="table-col" />
          </div>
        </div>
        -->

    <!-- <v-data-table
      hide-default-footer
      :disable-pagination="true"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
    /> -->
  </v-card>
</template>

<script>
import throttle from "lodash/throttle";
// import { QueryHelper } from '@/services/query-helper';
import { reportData7 } from "./report-data";

export default {
  name: "Report7",
  components: {},

  data() {
    return {
      loading: false,
      options: {},
      search: "",
      items: [],
      filter: {
        date: [
          this.$date(new Date())
            .startOf("month")
            .format("YYYY-MM-DD"),
          this.$date(new Date()).format("YYYY-MM-DD"),
        ],
      },
      chart: null,
      headers: [
        {
          text: "Степень риска SCORE",
          value: "name",
        },
        {
          text: "01.03.2020 - 07.03.20",
          value: "date_1",
          align: "center",
        },
        {
          text: "08.03.2020 - 14.03.20",
          value: "date_2",
          align: "center",
        },
        {
          text: "15.03.2020 - 21.03.20",
          value: "date_3",
          align: "center",
        },
        {
          text: "22.03.2020 - 28.03.20",
          value: "date_4",
          align: "center",
        },
        {
          text: "29.03.2020 - 24.04.20",
          value: "date_5",
          align: "center",
        },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },

  async mounted() {
    this.items = reportData7;
  },

  destroyed() {},

  methods: {
    async load() {
      this.loading = true;

      try {
        this.items = reportData7;
        // const params = {
        //   search: this.search,
        //   ...QueryHelper.tableSorting(this.options),
        //   ...QueryHelper.filter(this.filter),
        // };

        // const response = await this.$http.get('v1/reports/leader/report4', { params }).then((r) => r.data);

        // if (response.success) {
        //   this.items = response.data;
        //   this.chart.responsive();
        // }
      } catch (e) {
        // TODO: Обработать ошибку
        console.error(e);
      }

      this.loading = false;
    },

    apiSearch: throttle(async function apiSearchHandler() {
      await this.load();
    }, 1000),
  },
};
</script>

<style scoped></style>
