export const reportData1 = [
  {
    name: "Предресовые/послерейсовые  осмотры всего, из них",
    total_people: 10,
    not_allowed: 8,
    percent_total: 5,
    field: "-",
  },
  {
    name: "Высокая температура (более 37.0)",
    total_people: 20,
    not_allowed: 7,
    percent_total: 6,
    field: "-",
  },
  {
    name: "Высокие цифры АД (превышающие возрастную норму)",
    total_people: 25,
    not_allowed: 55,
    percent_total: 7,
    field: "-",
  },
  {
    name: "Высокие цифры ЧСС( превышающие возрастную норму)",
    total_people: 15,
    not_allowed: 5,
    percent_total: 8,
    field: "-",
  },
  {
    name: "Наличие паров этанола",
    total_people: 10,
    not_allowed: 4,
    percent_total: 10,
    field: "-",
  },
  {
    name: "ПЗМР (простая зрительно-моторная реакция)",
    total_people: 12,
    not_allowed: 5,
    percent_total: 15,
    field: "-",
  },
];

export const reportData2 = [
  {
    name: "Всего осмотров ПРМО/ПСМО",
    number_of_cases_total: 10,
    percent_of_total_research: 8,
    total_people_count: 5,
    percent_of_total_people: 5,
    difference_people_count: 5,
  },
  {
    name: "Количество случаев выявления повышенного АД",
    number_of_cases_total: 20,
    percent_of_total_research: 7,
    total_people_count: 6,
    percent_of_total_people: 6,
    difference_people_count: 14,
  },
  {
    name: "Количество случаев выявления превышения ЧСС",
    number_of_cases_total: 25,
    percent_of_total_research: 55,
    total_people_count: 7,
    percent_of_total_people: 7,
    difference_people_count: 18,
  },
];

export const reportData3 = [
  { name: "Всего работающего персонала", people_count: 10, percent: 8 },
  { name: "Подлежало проведению дополнительного медицинского осмотра", people_count: 20, percent: 7 },
  { name: "Прошли дополнительный медицинский осмотр всего, из них", people_count: 25, percent: 55 },
  { name: "Выявлено повышенное содержание глюкозы крови", people_count: 15, percent: 5 },
  { name: "Выявлено повышенное содержание холестерина крови", people_count: 10, percent: 4 },
  { name: "Выявлено повышенное артериальное давление (АД)", people_count: 12, percent: 5 },
  { name: "Выявлено превышение частоты ССС", people_count: 12, percent: 5 },
  { name: "Избыточная масса тела", people_count: 12, percent: 5 },
  { name: "Курение", people_count: 12, percent: 5 },
  { name: "Не выявлены фактора риска", people_count: 12, percent: 5 },
];

export const reportDataLine = [
  { date: new Date(2020, 3, 20), count: 158 },
  { date: new Date(2020, 3, 21), count: 108 },
  { date: new Date(2020, 3, 22), count: 208 },
  { date: new Date(2020, 3, 23), count: 138 },
  { date: new Date(2020, 3, 24), count: 178 },
  { date: new Date(2020, 3, 25), count: 118 },
  { date: new Date(2020, 3, 26), count: 198 },
];

export const reportData5 = [
  // {
  //   name: 'Показатель',
  //   date_1: '01.03.2020 - 07.03.20',
  //   date_2: '08.03.2020 - 14.03.20',
  //   date_3: '15.03.2020 - 21.03.20',
  //   date_4: '22.03.2020 - 28.03.20',
  //   date_5: '29.03.2020 - 24.04.20',
  // },
  {
    name: "Количество работающих, находящихся на постоянном динамическом наблюдении",
    date_1: "72",
    date_2: "78",
    date_3: "78",
    date_4: "82",
    date_5: "88",
  },
  {
    name: "Количество прошедших  физлиц, в том числе:",
    date_1: "88",
    date_2: "91",
    date_3: "85",
    date_4: "79",
    date_5: "84",
  },
  {
    name: "Предрейсовый  осмотр/отстранено",
    date_1: "15/0",
    date_2: "14/0",
    date_3: "15/0",
    date_4: "15/0",
    date_5: "12/0",
  },
  {
    name: "Послерейсовый  осмотр",
    date_1: "15",
    date_2: "14",
    date_3: "15",
    date_4: "15",
    date_5: "12",
  },
  {
    name: "Предсменный осмотр/отстранено",
    date_1: "41/0",
    date_2: "41/0",
    date_3: "39/0",
    date_4: "41/0",
    date_5: "41/0",
  },
  {
    name: "Предсменный осмотр",
    date_1: "41",
    date_2: "41",
    date_3: "39",
    date_4: "41",
    date_5: "41",
  },
  {
    name: "Прошедшие дополнительный медицинский осмотр, в том числе выявлено",
    date_1: "15",
    date_2: "9",
    date_3: "11",
    date_4: "6",
    date_5: "12",
  },
  {
    name: "Выявлено повышенное содержание глюкозы крови",
    date_1: "3",
    date_2: "1",
    date_3: "3",
    date_4: "0",
    date_5: "2",
  },
  {
    name: "Выявлено повышенное АД",
    date_1: "5",
    date_2: "4",
    date_3: "3",
    date_4: "2",
    date_5: "4",
  },
  {
    name: "Избыточная масса тела  (ИМТ)* ",
    date_1: "8",
    date_2: "4",
    date_3: "6",
    date_4: "4",
    date_5: "7",
  },
  {
    name: "Нарушение ФВД",
    date_1: "0",
    date_2: "0",
    date_3: "0",
    date_4: "0",
    date_5: "0",
  },
  {
    name: "Гиподинамия",
    date_1: "11",
    date_2: "7",
    date_3: "7",
    date_4: "4",
    date_5: "8",
  },
  {
    name: "Курение",
    date_1: "7",
    date_2: "4",
    date_3: "4",
    date_4: "2",
    date_5: "5",
  },
  {
    name: "Число лиц, находящихся на лечении без отрыва от производства",
    date_1: "12",
    date_2: "14",
    date_3: "9",
    date_4: "8",
    date_5: "16",
  },
  {
    name: "Количество вызовов МСМП с указанием причины",
    date_1: "1",
    date_2: "0",
    date_3: "0",
    date_4: "0",
    date_5: "1",
  },
  {
    name: "Количество случаев оказания первой /неотложной медицинской помощи",
    date_1: "2",
    date_2: "4",
    date_3: "3",
    date_4: "3",
    date_5: "4",
  },
  {
    name: "Количество манипуляций всего, в том числе:",
    date_1: "34",
    date_2: "30",
    date_3: "28",
    date_4: "26",
    date_5: "30",
  },
  {
    name: "парентеральные иньекции и инфузии",
    date_1: "12",
    date_2: "10",
    date_3: "10",
    date_4: "6",
    date_5: "14",
  },
  {
    name: "перевязки",
    date_1: "6",
    date_2: "4",
    date_3: "8",
    date_4: "6",
    date_5: "2",
  },
  {
    name: "измерение АД",
    date_1: "14",
    date_2: "10",
    date_3: "6",
    date_4: "6",
    date_5: "4",
  },
  {
    name: "измерение температуры",
    date_1: "2",
    date_2: "1",
    date_3: "4",
    date_4: "8",
    date_5: "10",
  },
  {
    name: "физиопроцедуры",
    date_1: "10",
    date_2: "15",
    date_3: "26",
    date_4: "19",
    date_5: "13",
  },
  {
    name: "Количество лиц, находящихся на листке нетрудоспособности",
    date_1: "6",
    date_2: "4",
    date_3: "8",
    date_4: "7",
    date_5: "6",
  },
  {
    name: "Количество случаев проведения Экспертизы временной нетрудоспособности (выдача, продление  ЛН на здравпункте)",
    date_1: "5",
    date_2: "4",
    date_3: "6",
    date_4: "5",
    date_5: "4",
  },
  {
    name: "Количество оформленных вновь индивидуальных Программ управления здоровьем",
    date_1: "9",
    date_2: "8",
    date_3: "3",
    date_4: "12",
    date_5: "14",
  },
  {
    name: "Количество проведенных телеконсультаций",
    date_1: "21",
    date_2: "16",
    date_3: "12",
    date_4: "28",
    date_5: "26",
  },
  {
    name: "Количество жалоб в адрес медицинских работников",
    date_1: "0",
    date_2: "0",
    date_3: "0",
    date_4: "0",
    date_5: "0",
  },
  {
    name: "Анкетирование на качество медицинского обслуживания, количество человек",
    date_1: "35",
    date_2: "24",
    date_3: "28",
    date_4: "41",
    date_5: "25",
  },
];

export const reportData9 = [
  {
    name: "Число лиц, находившихся  на листке нетрудоспособности, чел.",
    date_1: "18",
    date_2: "13",
    date_3: "8",
    date_4: "10",
  },
  {
    name: "Доля не болевших сотрудников – «Индекс здоровья»",
    date_1: "91",
    date_2: "93.5",
    date_3: "96",
    date_4: "95",
  },
  {
    name: "Число случаев дней временной  нетрудоспособности на 100 работающих*",
    date_1: "9",
    date_2: "6.5",
    date_3: "4",
    date_4: "5",
  },
  {
    name: "Число дней временной  нетрудоспособности на 100 работающих**",
    date_1: "126",
    date_2: "71.5",
    date_3: "40.8",
    date_4: "40",
  },
  {
    name: "Средняя продолжительность 1 случая",
    date_1: "14",
    date_2: "11",
    date_3: "10.2",
    date_4: "8",
  },
];

export const reportData10 = [
  {
    name: "Январь 2020",
    date_1: "ОРВИ",
    date_2: "12",
    date_3: "14",
    date_4: "12.5",
    date_5: "175",
  },
];

export const reportData12 = [
  {
    name: "1",
    date_1: "3",
    date_2: "4",
    date_3: "5",
    date_4: "6",
    date_5: "7",
    date_6: "8",
    date_7: "9",
  },
  {
    name: "Всего",
    date_1: "",
    date_2: "",
    date_3: "",
    date_4: "",
    date_5: "",
    date_6: "",
    date_7: "",
  },
  {
    name: "из них: работники, занятые на тяжелой работе и на работах с вредными и (или) опасными условиями труда, всего ",
    date_1: "",
    date_2: "",
    date_3: "",
    date_4: "",
    date_5: "",
    date_6: "",
    date_7: "",
  },
];

export const reportData7 = [
  {
    name: "Количество человек всего работающих , их них",
    date_1: "110",
    date_2: "",
    date_3: "",
    date_4: "",
    date_5: "",
    date_6: "",
    date_7: "",
  },
  {
    name: "Количество прошедших полный объем обследования (ПРОМО/ПСМО, скриниг, прием врача) ",
    date_1: "61",
    date_2: "*% от всего работающих",
    date_3: "",
    date_4: "",
    date_5: "",
    date_6: "",
    date_7: "",
  },
  {
    name: "Не имеют хронических заболеваний и факторов риска",
    date_1: "22 *** (разница между прошедшими всего и имеющими заболевания и факторы риска) ",
    date_2: "**% от числа прошедших  ",
    date_3: "",
    date_4: "",
    date_5: "",
    date_6: "",
    date_7: "",
  },
];

export const reportData13 = [
  {
    name: "Здравпункты врачебные всего, из них",
    date_1: "",
    date_2: "",
  },
  {
    name: "Всего по филиалу «Тулэнерго», в том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ленинский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ясногорский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Филиал «Калугаэнерго» всего, в том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Приокский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Боровской РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Число пунктов (точек) ПРМО/ПСМО всего, в том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Всего по филиалу «Тулэнерго», в  том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ленинский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ясногорский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Филиал «Калугаэнерго» всего, в том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Приокский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Боровской РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Врачей всего, из них",
    date_1: "",
    date_2: "",
  },
  {
    name: "Всего по филиалу «Тулэнерго», в  том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ленинский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ясногорский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Филиал «Калугаэнерго» всего, в том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Приокский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Боровской РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС ",
    date_1: "",
    date_2: "",
  },
  {
    name: "Средний медицинский персонал  всего, в том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Всего по филиалу «Тулэнерго», в  том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ленинский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ясногорский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Здравпункты фельдшерские  всего, из них",
    date_1: "",
    date_2: "",
  },
  {
    name: "Всего по филиалу «Тулэнерго», в  том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ленинский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ясногорский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Филиал «Калугаэнерго» всего, в том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Приокский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Боровской РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Средний медицинский персонал всего, в том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Всего по филиалу «Тулэнерго», в  том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ленинский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Ясногорский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Филиал «Калугаэнерго» всего, в том числе",
    date_1: "",
    date_2: "",
  },
  {
    name: "Приокский РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "Боровской РЭС",
    date_1: "",
    date_2: "",
  },
  {
    name: "... РЭС",
    date_1: "",
    date_2: "",
  },
];

export const reportData15 = [
  {
    name: "1",
    date_1: "2",
    date_2: "3",
    date_3: "4",
  },
  {
    name: "Врачи – всего, из них",
    date_1: "",
    date_2: "",
    date_3: "",
  },
  {
    name: "из них врач-терапевт (цеховой) ",
    date_1: "",
    date_2: "",
    date_3: "",
  },
  {
    name: "заведующий здравпунктом, врач-терапевт",
    date_1: "",
    date_2: "",
    date_3: "",
  },
  {
    name: "Врач-физиотерапевт",
    date_1: "",
    date_2: "",
    date_3: "",
  },
  {
    name: "И др.",
    date_1: "",
    date_2: "",
    date_3: "",
  },
  {
    name: "Средний медицинский персонал  всего, из них",
    date_1: "",
    date_2: "",
    date_3: "",
  },
  {
    name: "Фельдшер",
    date_1: "",
    date_2: "",
    date_3: "",
  },
  {
    name: "Заведующий здравпунктом-фельдшер",
    date_1: "",
    date_2: "",
    date_3: "",
  },
  {
    name: "Медицинская сестра",
    date_1: "",
    date_2: "",
    date_3: "",
  },
  {
    name: "Младший медицинский персонал всего",
    date_1: "",
    date_2: "",
    date_3: "",
  },
];
