export class QueryHelper {
  static tableSorting({ sortBy, sortDesc }) {
    const params = {};

    if (sortBy.length === 1 && sortDesc.length === 1) {
      [params["sort[field]"]] = sortBy;
      params["sort[direction]"] = sortDesc[0] ? "desc" : "asc";
    }

    return params;
  }

  /**
   * Обьект фильтра в квери стринг
   * @param {Object} data
   * @return {Object}
   */
  static filter(data) {
    const params = {};

    Object.keys(data).forEach(key => {
      if (data[key]) {
        params[`filter[${key}]`] = data[key];
      }
    });

    return params;
  }
}
