<template>
  <v-card class="mb-5">
    <v-card-title>
      !12. Сведения о результатах периодического осмотра
    </v-card-title>
    <v-card-text>TODO</v-card-text>
    <!--
    <v-card-text>
      <report-filter
        v-model="filter"
        @input="load"
      />
    </v-card-text>

    <v-data-table
      hide-default-footer
      :disable-pagination="true"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
    />
    -->
  </v-card>
</template>

<script>
import throttle from "lodash/throttle";
// import { QueryHelper } from '@/services/query-helper';
import { reportData12 } from "./report-data";

export default {
  name: "Report12",
  components: {},

  data() {
    return {
      loading: false,
      options: {},
      search: "",
      items: [],
      filter: {
        date: [
          this.$date(new Date())
            .startOf("month")
            .format("YYYY-MM-DD"),
          this.$date(new Date()).format("YYYY-MM-DD"),
        ],
      },
      chart: null,
      headers: [
        {
          text: "Наименование",
          value: "name",
        },
        {
          text: "Подлежало осмотрам",
          value: "date_1",
          align: "center",
        },
        {
          text: "Прошли",
          value: "date_2",
          align: "center",
        },
        {
          text: "Выявлено подозрений на профессиональное заболевание",
          value: "date_3",
          align: "center",
        },
        {
          text: "Не имели медицинских противопоказаний к работе",
          value: "date_4",
          align: "center",
        },
        {
          text: "Имели временные/постоянные медицинские противопоказания к работе",
          value: "date_5",
          align: "center",
        },
        {
          text: "Нуждаются в дополнительном обследовании в центре профпатологии",
          value: "date_6",
          align: "center",
        },
        {
          text: "Нуждаются в амбулаторном/стационарном обследовании и лечении",
          value: "date_7",
          align: "center",
        },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },

  // async mounted() {
  // },

  destroyed() {},

  methods: {
    async load() {
      this.loading = true;

      try {
        this.items = reportData12;
        // const params = {
        //   search: this.search,
        //   ...QueryHelper.tableSorting(this.options),
        //   ...QueryHelper.filter(this.filter),
        // };

        // const response = await this.$http.get('v1/reports/leader/report4', { params }).then((r) => r.data);

        // if (response.success) {
        //   this.items = response.data;
        //   this.chart.responsive();
        // }
      } catch (e) {
        // TODO: Обработать ошибку
        console.error(e);
      }

      this.loading = false;
    },

    apiSearch: throttle(async function apiSearchHandler() {
      await this.load();
    }, 1000),
  },
};
</script>

<style scoped></style>
